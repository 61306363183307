import * as React from 'react'
import Layout from '../components/Layout';

export default function store() {
    return(
<Layout>
    <h2>Welcome to our book store!</h2>
</Layout>
    );
};
